export default [
  {
    alt: 'Anbima - Gestão de Recursos',
    image: require('../image/regulatoryLogo-anbima.png'),
  },
  {
    alt: 'Anbima - Gestão de Patrimônio',
    image: require('../image/regulatoryLogo-anbima-patrimonio.png'),
  },
  {
    alt: 'Anbima - Como Investir',
    image: require('../image/regulatoryLogoComoInvestir.png'),
  },
]
