import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import QrCodeTesquroPremiado from './assets/image/qrCodeTesouroPremiado.png'

import { Wrapper } from './style'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import WhyIsItPrizedTreasure from './sections/why-is-it-prized-treasure/_index'
import SimpleAndQuickToParticipate from './sections/simple-and-quick-to-participate/_index'
import TotalPrizes from './sections/total-prizes/_index'
import CheckOutTheAwardsCalendar from './sections/check-out-the-awards-calendar/_index'
import FollowOutContentAboutDirectTreasure from './sections/fllow-our-content/_index'
import FaqTesouroPremiado from './sections/faq/_index'
import RegulatoryLogos from './sections/regulatory-logos/_index'

const TesouroPremiado = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()

  const oneLink = 'https://intergo.app/b5ac9e7f'

  const handleOpenModal = () => {
    setIsOpen(!isOpen)
  }

const ModalTesouroPremiado = domReady && (
  <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
    <QrCodeModal
      isModal={isOpen}
      setIsModal={setIsOpen}
      title='<span class="d-block fs-24 lh-30">Acesse a área de Tesouro Direto do Inter </span>'
      subtitle='<span class="d-block fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--500">Aponte a câmera do celular para o QR Code abaixo que você será direcionado para a área de investimento do Inter</span>'
      qrCode={QrCodeTesquroPremiado}
    />
  </Modal>
)

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {ModalTesouroPremiado}
        <Hero openModal={handleOpenModal} oneLink={oneLink} />
        <WhyIsItPrizedTreasure openModal={handleOpenModal} oneLink={oneLink} />
        <SimpleAndQuickToParticipate openModal={handleOpenModal} oneLink={oneLink} />
        <TotalPrizes openModal={handleOpenModal} oneLink={oneLink} />
        <CheckOutTheAwardsCalendar />
        <FollowOutContentAboutDirectTreasure />
        <FaqTesouroPremiado />
        <RegulatoryLogos />
      </Layout>
    </Wrapper>
  )
}

export default TesouroPremiado
