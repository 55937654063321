import styled from 'styled-components'
import { orange } from 'src/styles/colors'

export const Button = styled.button`
  height: 48px;
  width: 100%;
  border-radius: 8px;
  background: ${orange.extra};
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border: none;
  margin-top: 16px;

`

export const ButtonReverse = styled.a`
  height: 48px;
  width: 100%;
  border-radius: 8px;
  color: ${orange.extra};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border: 1px solid ${orange.extra};
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${orange.extra};
  }


`
