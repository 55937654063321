
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled, { css } from 'styled-components'

type ButtonProps = {
  disabled?: boolean;
}

export const Section = styled.section`

  table {
    height: 108px;
    width: 100%;
  }

  table, th {
    border-right: 1px solid ${grayscale[300]};
    border-bottom: 1px solid ${grayscale[300]};
    background: ${grayscale[100]} ;
    height: 108px;
  }

  table, th:last-child {
    border-right: none;
  }

  table {
    height: 68px;
    border-bottom: 1px solid ${grayscale[300]};
    min-width: 99px;

    tr {
      font-weight: 400;
      color: ${grayscale[500]};

      &:last-child {
        color: #72370E;
        font-weight: 600;
      }
      td {
        height: 68px;
        border-bottom: 1px solid ${grayscale[300]};
        min-width: 99px;
      }
    }
  }
`

export const TableContainer = styled.div`
  overflow-x: auto;
`

export const Button = styled.a`
  border-radius: 8px;
  width: 100%;
  height: 32px;
  font-size: 10px;
  line-height: 12px;
  background-color: ${orange.extra};
  color: ${white};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

  ${({ disabled }: ButtonProps) => disabled && css`
    background-color: ${grayscale[200]};
    color: ${grayscale[300]};

    &:hover {
      color: ${grayscale[300]};
      cursor: no-drop;
    }
  `}

  @media (min-width: ${breakpoints.md}){
    max-width: 116px;
  }
`

export const Tr = styled.tr`
  background: ${white};

  ${({ disabled }: ButtonProps) => !disabled && css`
    background-color: ${grayscale[300]};
    color: ${grayscale[400]};

    td {
      border-bottom: 1px solid ${white} !important;
    }
  `}
`
