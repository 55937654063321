import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import investimentosTesouroDireto from '../../assets/image/Investimentos_Tesouro_Banner_01.gif'

import { Circle, Button, Section } from './style'

interface IProps {
  openModal: () => void;
  oneLink: string;
}

const SimpleAndQuickToParticipate = ({ openModal, oneLink }: IProps) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>
              É simples e rápido participar
            </h2>
            <div className='d-flex mb-3'>
              <Circle>1</Circle>
              <p className='fs-16 lh-19 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400 mb-0 ml-2'>
                Acesse o Super App do Inter
              </p>
            </div>
            <div className='d-flex mb-3'>
              <Circle>2</Circle>
              <p className='fs-16 lh-19 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400 mb-0 ml-2'>
                Escolha algum título do Tesouro
              </p>
            </div>
            <div className='d-flex mb-3'>
              <Circle>3</Circle>
              <p className='fs-16 lh-19 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400 mb-0 ml-2'>
                Faça aportes em qualquer valor
              </p>
            </div>
            <div className='d-flex mb-3'>
              <Circle>4</Circle>
              <p className='fs-16 lh-19 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400 mb-0 ml-2'>
                Pronto. Agora é só torcer!
              </p>
            </div>
            {
              width >= widths.md && (
                <Button
                  title='Quero participar'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'É simples e rápido participar',
                      element_action: 'click button',
                      element_name: 'Quero participar',
                    })
                  }}
                >Quero participar
                </Button>
              )
            }
          </div>
          <div className='col-12 col-md-6'>
            <img src={investimentosTesouroDireto} className='w-100' />
            {
                width < widths.md && (
                  <a
                    href={oneLink}
                    title='Quero participar'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        section_name: 'É simples e rápido participar',
                        element_action: 'click button',
                        element_name: 'Quero participar',
                      })
                    }}
                  >
                    <Button>Quero participar</Button>
                  </a>
                )
              }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SimpleAndQuickToParticipate
