import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import logoRegulatory from '../../assets/data/_regulatoryLogoTesouroPremiado'

import { Section } from './style'

type LogoRegulatoryProps = {
  image: string;
  alt: string;
}

const RegulatoryLogos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://marketing.bancointer.com.br/arquivos/pdf/investimentos/regulamento-tesouro-premiado-2024.pdf'

  return (
    <Section className='pt-4 pb-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-md-flex justify-content-md-center align-items-md-center text-center'>
            {
              logoRegulatory.map((item: LogoRegulatoryProps) => (
                <img src={item.image} alt={item.alt} className='ml-md-3 mb-5' key={item.image} />
              ))
            }
          </div>
          <div className='col-12'>
            <p className='fs-12 lh-14 text-grayscale--500 fw-400 text-md-center'>
              * Participantes do sorteio mensal também entram no sorteio anual, contanto que mantenham o investimento
              até 31/12/2024, recebendo assim dois números da sorte. Investidores que já realizaram aportes em Tesouro
              antes do início da campanha, concorrem apenas ao sorteio anual, ganhando um número da sorte, independentemente
              do número de aportes. Caso você seja contemplado em um dos sorteios mensais, só poderá concorrer novamente
              no sorteio anual.
              <a
                className='text-grayscale--500'
                target='_blank'
                rel='noreferrer'
                href={url}
                title='Confira o regulamento'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    section_name: 'Regulamento',
                    element_action: 'click button',
                    element_name: 'Confira o regulamento.',
                    redirect_url: url,
                  })
                }}
              >
                <u> Confira o regulamento. </u>
              </a>
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default RegulatoryLogos
