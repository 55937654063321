import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background: ${grayscale[100]};
  min-height:  354px;
`

export const Button = styled.a`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: ${orange.extra};
  color: ${white};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border: none;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }
`
