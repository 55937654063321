import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'

import followOurContentJSON from '../../assets/data/followOurContent.json'
import { Section, Card, Box } from './style'

type FollowOutContent = {
  link: string;
  description: string;
  title: string;
  data: string;
}

const FollowOutContentAboutDirectTreasure = () => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-center mb-5'>
              <span className='d-block'>Acompanhe os nossos conteúdos sobre o</span>  Tesouro Direto
            </h2>
          </div>
          {
            followOurContentJSON.map((item: FollowOutContent) => (
              <div className='col-12 col-md-4' key={item.description}>
                <Card className='mx-auto'>
                  <div className='d-flex d-md-block d-lg-flex mb-3'>
                    <Box>Para simplificar a vida</Box>
                  </div>
                  <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mb-3 title'>{item.title}</h3>
                  <h3 className='fs-12 lh-15 mb-2'>{item.data}</h3>
                  {
                    width > widths.md && (
                      <p className='fs-16 lh-19 text-grayscale--500 fw-400'>
                        {item.description}
                      </p>
                    )
                  }
                  <div>
                    <a
                      href={item.link}
                      target='_blank'
                      rel='noreferrer'
                      title='Leia mais'
                      className='fs-14 lh-16 d-flex align-items-center justify-content-end text-orange--extra mb-0'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_06',
                          section_name: 'Acompanhe os nossos conteúdos sobre o Tesouro Direto',
                          element_action: 'click button',
                          element_name: 'Leia mais',
                          redirect_url: item.link,
                        })
                      }}
                    >
                      Leia mais
                      <ArrowRight height={24} width={24} color='#FF7A00' className='ml-2' />
                    </a>
                  </div>
                </Card>
              </div>
            ))
          }
        </div>
      </div>
    </Section>
  )
}

export default FollowOutContentAboutDirectTreasure
