import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, TableContainer, Button, Tr } from './style'
import tableJSON from '../../assets/data/table.json'

type TableProps = {
  periodoDaParticipacao: string;
  dataDoSorteio: string;
  valordoPremio: string;
  buttonDisabled: boolean;
  urlNumbers: string;
}

const CheckOutTheAwardsCalendar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='py-5' id='check-out-the-awards-calendar'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-center mb-4'>
              Confira o calendário das premiações
            </h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 text-grayscale--500 fw-400 text-center mb-5'>
              Os Sorteios serão apurados com base nas extrações da Loteria Federal do Brasil conforme as datas abaixo:
            </p>
            <TableContainer>
              <table>
                <tr>
                  <th className='fs-12 lh-14 fs-md-18 lh-md-22 fw-700 text-grayscale--500 text-center'>Período de participação</th>
                  <th className='fs-12 lh-14 fs-md-18 lh-md-22 fw-700 text-grayscale--500 text-center'>Data do sorteio</th>
                  <th className='fs-12 lh-14 fs-md-18 l-md-22 fw-700 text-grayscale--500 text-center'>Valor do prêmio</th>
                  <th className='fs-12 lh-14 fs-md-18 lh-md-22 fw-700 text-grayscale--500 text-center'>Números da sorte</th>
                </tr>
                {
                  tableJSON.map((item: TableProps) => (
                    <Tr disabled={item.buttonDisabled} key={item.dataDoSorteio}>
                      <td className='fs-12 lh-14 fs-md-18 lh-md-21 text-center pl-3'>{item.periodoDaParticipacao}</td>
                      <td className='fs-12 lh-14 fs-md-18 lh-md-21 text-center pr-3'>{item.dataDoSorteio}</td>
                      <td className='fs-12 lh-14 fs-md-18 lh-md-21 text-center pr-3'>{item.valordoPremio}</td>
                      <td className='fs-12 lh-14 fs-md-18 lh-md-21 fw-400 text-center pr-3'>
                        {
                          !item.buttonDisabled
                          ? (
                            <Button
                              target='_blank'
                              rel='noreferrer'
                              href={item.urlNumbers}
                              className='mx-auto'
                              title='Conferir'
                              onClick={() => {
                                sendDatalayerEvent({
                                  section: 'dobra_05',
                                  section_name: 'Confira o calendário das premiações',
                                  element_action: 'click button',
                                  element_name: 'Conferir',
                                  redirect_url: item.urlNumbers,
                                })
                              }}
                            >
                              Conferir
                            </Button>
                          )
                        : (
                          <Button
                            disabled
                            className='mx-auto'
                          >
                            Conferir
                          </Button>
                          )
                        }
                      </td>
                    </Tr>
                  ))
                }
                <CheckOutTheAwardsCalendar.AnnualNumberRow />
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </Section>
  )
}

CheckOutTheAwardsCalendar.AnnualNumberRow = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const entry = {
    periodoDaParticipacao: "⭐08/01/24 a 31/12/24",
    dataDoSorteio: "05/02/2025",
    valordoPremio: "R$ 100.000,00",
    buttonDisabled: false,
    urlNumbers: "https://marketing.bancointer.com.br/arquivos/pdf/investimentos/numeros-anuais-compact.pdf",
  }

  const getButtonUrl = () => {
    const env = process.env.NODE_ENV as string
    if (env === 'development') {
      return 'http://localhost:8000/numeros-anuais-compacto'
    }

    return process.env.USE_CSV_PAGE === 'true' ? 'https://inter.co/numeros-anuais-compacto' : entry.urlNumbers
  }

  return (
    <Tr disabled={entry.buttonDisabled} key={entry.dataDoSorteio}>
      <td className='fs-12 lh-14 fs-md-18 lh-md-21 text-center pl-3'>{entry.periodoDaParticipacao}</td>
      <td className='fs-12 lh-14 fs-md-18 lh-md-21 text-center pr-3'>{entry.dataDoSorteio}</td>
      <td className='fs-12 lh-14 fs-md-18 lh-md-21 text-center pr-3'>{entry.valordoPremio}</td>
      <td className='fs-12 lh-14 fs-md-18 lh-md-21 fw-400 text-center pr-3'>
        <Button
          disabled={entry.buttonDisabled}
          target='_blank'
          rel='noreferrer'
          href={getButtonUrl()}
          className='mx-auto'
          title='Conferir'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_05',
              section_name: 'Confira o calendário das premiações',
              element_action: 'click button',
              element_name: 'Conferir',
              redirect_url: entry.urlNumbers,
            })
          }}
        >
          Conferir
        </Button>
      </td>
    </Tr>
  )
}

export default CheckOutTheAwardsCalendar
