import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  min-height: 548px;

  @media ${device.tablet} {
    min-height: 416px;
  }
`

export const Circle = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: ${grayscale[500]} ;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
`
export const Button = styled.a`
   width: 100%;
   height: 48px;
   border-radius: 8px;
   background: ${orange.extra};
   color: #fff;
   font-weight: 600;
   font-size: 14px;
   line-height: 20px;
   text-align: center;
   border: none;
   margin-top: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;

   &:focus, &:hover {
    outline: none;
    color: ${white};
   }
`
