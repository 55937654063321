import React from 'react'

import { Section } from './style'
import faqData from '../../pageContext.json'

// Components
import Faq from 'src/components/Faq'

const FaqTesouroPremiado = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 mb-5 text-grayscale--500 fw-600'>
              O que você precisa saber sobre o Tesouro Direto Premiado do Inter
            </h2>
          </div>
          <Faq
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            answerData={faqData.structuredData.faq}
            className='#161616'
            questionColor='#161616'
            answersColor='#161616'
          />
        </div>
      </div>
    </Section>
  )
}

export default FaqTesouroPremiado
