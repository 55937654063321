import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      iconeDollar: imageSharp(fluid: {originalName: { regex: "/iconeDollar/" }}) {
        fluid(maxWidth: 72, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      investimentosTesouroDireto: imageSharp(fluid: {originalName: { regex: "/Investimentos_Tesouro_Banner_01/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
