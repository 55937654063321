import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import pageQuery from '../../pageQuery'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { Section, Button } from './style'
interface IProps {
  openModal: () => void;
  oneLink: string;
}

const WhyIsItPrizedTreasure = ({ openModal, oneLink }: IProps) => {
  const data = pageQuery()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://marketing.bancointer.com.br/arquivos/pdf/investimentos/regulamento-tesouro-premiado-2024.pdf'

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-4 mb-3'>
            <Img fluid={data.iconeDollar.fluid} />
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-md-0'>
              <span className='d-block'>O que é Tesouro</span> Premiado?
            </h2>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-white fw-400'>
              Ao investir nos títulos do Governo Federal pelo Inter, os novos investidores receberão um número da sorte para
              concorrer a 10 mil reais mensais e outro número para concorrer ao prêmio único de 100 mil reais no final do ano.
            </p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-white fw-400'>
              Os investidores que já possuem Tesouro Direto e mantiverem seus investimentos até o final do ano receberão apenas um
              número da sorte para concorrer ao grande prêmio final.
            </p>
            {
              width >= WIDTH_MD ? (
                <Button
                  title='Quero participar'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      section_name: 'O que é Tesouro Premiado?',
                      element_action: 'click button',
                      element_name: 'Quero participar',
                    })
                  }}
                >Quero participar
                </Button>
              ) : (
                <Button
                  href={oneLink}
                  title='Quero participar'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      section_name: 'O que é Tesouro Premiado?',
                      element_action: 'click button',
                      element_name: 'Quero participar',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  Quero participar
                </Button>
              )
            }
            <a
              href={url}
              target='_blank'
              rel='noreferrer'
              className='text-white fs-16 lh-20 fs-md-18 lh-md-22 mt-2 d-block'
              title='Consulte o regulamento.'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'O que é Tesouro Premiado?',
                  element_action: 'click button',
                  element_name: 'Consulte o regulamento.',
                })
              }}
            >*Consulte o regulamento.
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhyIsItPrizedTreasure
