import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Button, Section } from './style'

interface IProps {
  openModal: () => void;
  oneLink: string;
}

const TotalPrizes = ({ openModal, oneLink }: IProps) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 mb-3'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/tesouro-premiado-2/image.png'
              arrayNumbers={[ 300, 336, 376, 500 ]} altDescription=''
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400'>
              No total, serão distribuídos <strong> 220 mil</strong> reais no Tesouro Premiado. Serão 12 prêmios de
              <strong> 10 mil</strong> reais ao longo do ano, além de um super prêmio de
              <strong> 100 mil</strong> reais no final do ano.
            </p>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400'>
              Essa é a sua chance para você que já investe nos títulos do Tesouro Direto, ou esperava uma boa oportunidade para investir, esse momento chegou!
            </p>
            {
              width >= widths.md ? (
                <Button
                  title='Quero participar'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Total de prêmios',
                      element_action: 'click button',
                      element_name: 'Quero participar',
                    })
                  }}
                >Quero participar
                </Button>
              ) : (
                <a
                  href={oneLink}
                  title='Quero participar'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Total de prêmios',
                      element_action: 'click button',
                      element_name: 'Quero participar',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  <Button>Quero participar</Button>
                </a>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TotalPrizes
